<template>
    <div>
        <div class="sim-font-bold mb-2 uppercase"
             :class="{
                 'sim-text-small': size === 'md',
                 'sim-text-extra-small': size === 'sm',
             }"
        >
            {{ title ?? property?.name }}
        </div>

        <LazyUiColorSwatchSelectorSquare
            v-if="property?.isTypeColor()"
            v-model="value"
            :values="property.getAttributes()"
            :is-item-disabled="(attr: any) => !displayOnly && !availableVariations?.some(variation => variation.hasAttribute(attr as ProductVariationPropertyAttributeModel))"
            :class="selectorClass"
            :loading="loading"
            :size="size"
            :color="color"
            :display-only="displayOnly"
            @change="handleChange"
        />

        <LazyUiAttributeSelector
            v-else-if="property?.isTypeRadio() || property?.isTypeSelect()"
            v-model="value"
            :class="selectorClass"
            label-getter="name"
            :is-item-disabled="(attr: any) => !displayOnly && !availableVariations?.some(variation => variation.hasAttribute(attr))"
            :values="property.getAttributes()"
            :loading="loading"
            :size="size"
            :color="color"
            :display-only="displayOnly"
            @change="handleChange"
        />

        <!-- -------------------------------------------------------------------- -->

        <!--  VARIATION SELECTOR  -->
        <!--  Selects variations instead of attributes in case of multiple variations with the same attributes  -->
        <LazyUiAttributeSelector
            v-else
            v-model="variationValue"
            :values="indistinguishableVariations ?? []"
            label-getter="name"
            :class="selectorClass"
            :loading="loading"
            :size="size"
            :color="color"
            :display-only="displayOnly"
            @change="handleChange"
        />

    </div>
</template>

<script lang="ts" setup>
import type {
    ProductVariationPropertyAttributeModel
} from '@simploshop-models/custom/product-variation-property-attribute.model'
import type { VariationSelectorPropertyChangeData } from '@sim-api-composables/data-transforms'
import type { SizeProp } from '@core/types/components'
import type { ComponentPropsOverride } from '@core/types/components/CoreUiSelector'

const {
    property,
    availableVariations,
    loading,
    displayOnly,
    selectorClass,
    size = 'md',
    color,
    // variation selector
    indistinguishableVariations,
    title,
} = defineProps<{
    property?: InstanceType<typeof ProductVariationPropertyModel> | null
    availableVariations?: InstanceType<typeof ProductVariationModel>[]
    loading?: boolean
    displayOnly?: boolean
    selectorClass?: string
    size?: SizeProp
    color?: ComponentPropsOverride['color']
    /**
     * Variations to pick from when all attributes are selected
     * & multiple variations with the same attributes exist.
     */
    indistinguishableVariations?: InstanceType<typeof ProductVariationModel>[]
    title?: string
}>()

const value = defineModel<InstanceType<typeof ProductVariationPropertyAttributeModel> | null>()
// Selects variations instead of attributes in case of multiple variations with the same attributes
const variationValue = defineModel<InstanceType<typeof ProductVariationModel> | null>('variation')

const emit = defineEmits<{
    change: [data: VariationSelectorPropertyChangeData]
}>()

function handleChange(val: InstanceType<typeof ProductVariationPropertyAttributeModel> | null) {
    if (!property) return
    emit('change', {
        property: property,
        value: val ?? null,
    })
}

</script>

<style lang="scss" scoped>

</style>
